.card {
  @apply transition duration-300 ease-in-out rounded-md;

  &:focus-within,
  &:active {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2),
        0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
    }
  }
}
