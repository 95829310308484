@tailwind base;
@tailwind components;

a,
button,
:focus {
  outline: none;
}

.btn {
  @apply rounded font-semibold text-xl min-h-action transition duration-300 ease-in-out;
}

.btn:focus {
  @apply outline-none;
}

.global-rich-text {
  @apply break-words;

  a {
    @apply underline;
  }
}

.link-tertiary {
  @apply text-primary underline;

  &:active,
  &:focus {
    @apply text-primary;
  }
  &:hover {
    @apply text-primary;
  }
}

.is-primary {
  @apply bg-primary text-white;

  &:active,
  &:focus {
    @apply bg-primary text-white;
  }
  &:hover {
    @apply bg-primary-darken text-white;
  }
}

.is-secondary {
  @apply bg-secondary text-primary;

  &:active,
  &:focus {
    @apply bg-secondary text-primary;
  }
  &:hover {
    @apply bg-secondary-darken text-primary;
  }
}

.is-tertiary {
  @apply bg-inverse text-primary;

  &:active,
  &:focus {
    @apply bg-inverse text-primary;
  }
  &:hover {
    @apply bg-inverse text-primary;
  }
}

.is-inverse {
  @apply bg-inverse text-black;

  &:active,
  &:focus {
    @apply bg-primary-lighten text-white;
  }
  &:hover {
    @apply bg-inverse-darken text-black;
  }
}

.is-white {
  @apply bg-white text-black;

  &:active,
  &:focus {
     @apply bg-inverse-lighten text-primary-lighten;
   }
  &:hover {
     @apply bg-primary text-white;
   }
}

.is-mood-option {
  @apply bg-inverse;

&:active,
&:focus {
   @apply bg-primary hover:bg-primary-darken;
 }
&:hover {
   @apply bg-inverse-darken;
 }
}

.is-dark {
  @apply bg-tertiary text-white;

  &:active,
  &:focus {
     @apply bg-tertiary text-white;
   }
  &:hover {
     @apply bg-tertiary-darken text-white;
   }
}

/*buttons or links*/
.action {
  &.is-xs {
    @apply px-2;
  }
  &.is-sm {
    @apply px-4;
  }
  &.is-md {
    @apply px-8;
  }
  &.is-lg {
    @apply px-16;
  }
}

.gridContainer {
  @apply grid justify-center gap-4 mb-8;

  grid-template-rows: auto;
  --grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

  @screen sm {
    --grid-template-columns: repeat(auto-fill, 300px);
  }

  grid-template-columns: var(--grid-template-columns);
}

@tailwind utilities;

:root {
  --black: #1E293B;
  --white: #fff;
  --gray-100: #F1F5F9;
  --gray-200: #f0f0f0;
  --gray-300: #d8d8d8;
  --gray-400: #bfbfbf;
  --gray-500: #64748B;
  --gray-600: #939393;
  --gray-700: #696969;
  --gray-800: #404040;

  --red-100: #f97773;
  --red-200: #f85651;
  --red-300: #de4d48;

  --beige-100: #f4f5ef;
  --beige-200: #eef1ef;
  
}

.theme-life,
.theme-connect {
  --black: #0F172A;       /** blue-gray/900 */

  --gray-100: #F1F5F9;
  --gray-200: #E2E8F0;
  --gray-300: #CBD5E1;
  --gray-400: #94A3B8;
  --gray-500: #64748B;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1E293B;


  --color-bg-primary-lighten: #0D9488;    /** teal/600 */
  --color-bg-primary: #0F766E;            /** teal/700 */
  --color-bg-primary-darken: #115E59;     /** teal/800 */

  --color-bg-secondary-lighten: #F0FDFA;  /** teal/50  */
  --color-bg-secondary: #F0FDFA;          /** teal/50  */
  --color-bg-secondary-darken: #CCFBF1;   /** teal/100 */

  --color-bg-tertiary-lighten: #475569;   /** blue-gray/600 */
  --color-bg-tertiary: #334155;           /** blue-gray/700 */
  --color-bg-tertiary-darken: #1E293B;    /** blue-gray/800 */

  --color-bg-inverse-lighten: #F8FAFC;    /** blue-gray/50  */
  --color-bg-inverse: #F1F5F9;            /** blue-gray/100 */
  --color-bg-inverse-darken: #E2E8F0;     /** blue-gray/200 */
  --color-bg-even-brighter: #94A3B8;    /** blue-gray/400 */

  --color-bg-accent-lighten: #F8FAFC;     /** blue-gray/50    */
  --color-bg-accent: #F1F5F9;             /** blue-gray/100    */
  --color-bg-accent-darken: #E2E8F0;      /** blue-gray/200  */
}

.theme-reach {
  --color-bg-primary-lighten: #50596b;
  --color-bg-primary: #3a4558;
  --color-bg-primary-darken: #243046;

  --color-bg-secondary-lighten: #738198;
  --color-bg-secondary: #334562;
  --color-bg-secondary-darken: #29364a;

  --color-bg-tertiary-lighten: #2771d5;
  --color-bg-tertiary: #1c51b9;
  --color-bg-tertiary-darken: #204896;

  --color-bg-inverse-lighten: var(--beige-100);
  --color-bg-inverse: var(--beige-200);
  --color-bg-inverse-darken: #738198;

  --color-bg-accent-lighten: #abe6ff;
  --color-bg-accent: #90deff;
  --color-bg-accent-darken: #8bd9fa;
}

.theme-elevate {
  --color-bg-primary-darken: #261c2e;
}

.theme-thrive {
  --color-bg-primary-darken: #261c2e;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

body {
  font-weight: 400;
  font-size: 16px;
  color: var(--black);
}

ol,
ul,
li {
  list-style: none;
  text-indent: 0;
  list-style-type: none;
}
